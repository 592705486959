import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Front from "../components/front"
import About from "../components/about"
import Acts from "../components/acts"
import Contact from "../components/contact"

import "../styles/style.scss"
import "../styles/style.css"
import WhatsappIcon from "../components/whatsappIcon/whatsappIcon"



const IndexPage = () => (
  <Layout>
    <SEO title="Lekha Suresh & Ashok Suresh advocates" />
    <Front/>
    <About/>
    <Contact/>
    <Acts/>
    <WhatsappIcon number="9447374596" />
  </Layout>
)

export default IndexPage
