import React from "react"
import IconButton from "@mui/material/IconButton"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import { styled } from "@mui/material/styles"

const CustomWhatsappIcon = styled(IconButton)`
  color: white;
  background-color: #128C7E;
  position: sticky;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  box-shadow: 2px 2px 5px black;

  svg {
    width: 35px;
    height: 35px;
  }

  :hover {
    background-color: #075E54;
  }
`

const WhatsappIcon = props => {
  const href = `https://wa.me/${props.number}`
  return (
    <CustomWhatsappIcon
      color="secondary"
      aria-label="add an alarm"
      size="large"
      href={href}
      target="_blank"
    >
      <WhatsAppIcon />
    </CustomWhatsappIcon>
  )
}

export default WhatsappIcon
